<template>
  <div id="eu-dashboard">
    <europarl-saved-searches-form />
    <base-layout>
      <!-- <template v-slot:navigation-menu>
        <navigation-menu
          boardTitle="EU dashboard"
        />
      </template> -->

      <template v-slot:drawer-left>
        <sidebar v-if="europarlFiltersAreSet" />
      </template>

      <template v-slot:identity>
        <a
          href="/eu/dashboard"
          class="d-flex align-start"
        >
          <img
            style="cursor: pointer;"
            src="../../../assets/logo-transparant-groot.png"
            alt="Polpo - Politieke monitoring"
            height="42"
          >
        </a>
      </template>

      <template v-slot:searchbar>
        <!-- <search-bar /> -->
        <search-bar
          on-search-action="europarlGetAllTimelineItems"
          set-query-action="setQuery"
          search-query="searchParameterQuery"
          :query-length="1000"
          :placeholder="$t('document.searchInDocuments')"
        />
      </template>

      <template v-slot:main-content>
        <v-container
          fluid

        >
          <v-row v-if="europarlTimelineItemsCountTotal > 0 && statisticsTrend">
            <v-col cols="12">
              <v-expansion-panels
                :focusable="false"
                v-if="trendAnalyticsExpanded"
                v-model="singlepanel"
                multiple
              >
                <trend-analytics-chart
                  statisticsTrendType="europarlStatisticsTrend"
                  statisticsTimelineType="europarlGetAllTimelineItems"
                />
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
              v-if="europarlTimelineItemsCountTotal >= 0"
              cols="12"
              lg="6"
              xl="6">
              <list-timeline
                nextItemUrlString="europarlNextItemUrl"
                setNextItemUrlString="europarlSetNextItemUrl"
                timeline-items-string="europarlTimelineItems"
                timeline-items-count-total-string="europarlTimelineItemsCountTotal"
                timeline-items-has-more-string="europarlTimelineItemsHasMore"
                get-all-timeline-items-next-string="europarlGetAllTimelineItemsNext"
                get-all-timeline-items-string="europarlGetAllTimelineItems"
                maxDateRangeTimelineItemsTotalString="europarlMaxDateRangeTimelineItemsTotal"
                maxDateRangeTimelineItemsHasMoreString="europarlMaxDateRangeTimelineItemsHasMore"
              />
            </v-col>
            <v-col
              v-if="europarlTimelineItemsCountTotal > 0 && statisticsTrend"
              cols="12"
              lg="6"
              xl="6"
              class="d-none d-lg-block"
            >
              <v-expansion-panels
                :focusable="false"
                v-model="panel"
                multiple
              >
                <trend-analytics-chart
                  v-if="!trendAnalyticsExpanded"
                  statisticsTrendType="europarlStatisticsTrend"
                  statisticsTimelineType="europarlGetAllTimelineItems"
                />
                <list-stakeholders
                  title="Activiteit"
                  analyticsConfigKey="euAnalyticsTypes"
                  analyticsGetter="europarlStatisticsStakeholders"
                  analyticsConfigDefaultKey="defaultAnalyticsCode"
                  analyticsLocalStorageKey="analyticsCode"
                  analyticsFunction="europarlGetStatistics"
                />
                <!-- <list-trending-topics /> -->
              </v-expansion-panels>
              <!-- <list-agenda /> -->
            </v-col>
          </v-row>
        </v-container>

      </template>

      <template v-slot:drawer-right>
        <saved-searches
          class="mt-n4"
          saved-searches-category-string="europarl"
          current-search-string="europarlCurrentSearch"
          saved-searches-string="europarlAllSavedSearches"
          recent-searches-string="europarlAllRecentSearches"
          max-number-of-searches-string="europarlMaxNumberOfSearches"
          all-search-parameters-string="allSearchParameters"
          set-current-search-string="europarlSetCurrentSearch"
          remove-search-string="europarlRemoveSearch"
          update-search-string="europarlUpdateSearch"
          set-all-search-parameters-string="europarlSetAllSearchParameters"
          set-dialog-form-saved-searches-visible-string=
            "setEuroparlDialogFormSavedSearchesVisible"
          saved-searches-item-detail-recent-string="EuroparlSavedSearchesItemDetailRecent"
          saved-searches-item-detail-saved-string="EuroparlSavedSearchesItemDetailSaved"
        />
      </template>

    </base-layout>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex';

// @ is an alias to /src
import BaseLayout from '@/components/BaseLayout.vue';
import TrendAnalyticsChart from '@/components/TrendAnalyticsChart.vue';
import SearchBar from '@/components/SearchBar.vue';
// import NavigationMenu from '@/components/NavigationMenu.vue';
import ListTimeline from '@/components/ListTimeline.vue';
// import ListAgenda from '@/components/ListAgenda.vue';
import ListStakeholders from '@/components/ListStakeholders.vue';
// import ListTrendingTopics from '@/components/ListTrendingTopics.vue';
import SavedSearches from '@/components/SavedSearches.vue';
import FeatureTeaserListManager from '@/components/FeatureTeaserListManager.vue';
import OldLinkWarning from '@/components/OldLinkWarning.vue';
import AgendaTeaserDialog from '@/modules/agenda/components/AgendaTeaserDialog.vue';
import { showDialog } from '@/helpers/dialogHelper';
import EuroparlSavedSearchesForm from '@/modules/europarl/components/EuroparlSavedSearchesForm.vue';
import Sidebar from '../components/EuroparlSidebar.vue';

export default {
  name: 'eu-dashboard',

  components: {
    EuroparlSavedSearchesForm,
    BaseLayout,
    TrendAnalyticsChart,
    Sidebar,
    SearchBar,
    // NavigationMenu,
    ListTimeline,
    // ListAgenda,
    ListStakeholders,
    // ListTrendingTopics,
    SavedSearches,
  },

  computed: {
    ...mapGetters({
      authHasModuleAccess: 'authHasModuleAccess',
      europarlTimelineItems: 'europarlTimelineItems',
      europarlTimelineItemsCountTotal: 'europarlTimelineItemsCountTotal',
      europarlAllSearchParameters: 'europarlAllSearchParameters',
      searchParameters: 'allSearchParameters',
      statisticsTrend: 'europarlStatisticsTrend',
      trendAnalyticsExpanded: 'trendAnalyticsExpanded',
      isIE11: 'isIE11',
    }),
  },

  data: () => ({
    europarlFiltersAreSet: false,
    toggleTrendAnalyticsExpanded: false,
    singlepanel: [0],
    panel: [0, 1, 2],
    prevRoute: null,
  }),

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },

  methods: {
    showTeaserDialog() {
      showDialog(FeatureTeaserListManager);
    },
    showTeaserAgendaModule() {
      showDialog(AgendaTeaserDialog);
    },

    async parseQueryParams(queryParams) {
      const {
        query,
        groupPaths,
        committees,
        docTypesEc, // old
        docTypesEpPl, // old
        docTypesEpCom, // old
      } = queryParams;
      const dateRangeISO = {
        startDate: queryParams.startDate,
        endDate: queryParams.endDate,
      };

      if (docTypesEc || docTypesEpPl || docTypesEpCom) {
        showDialog(OldLinkWarning);
      }

      // Check if there are any query parameters set
      if (query) {
        this.$store.dispatch('setQuery', query);
      }

      this.$store.dispatch('setGroupPaths', (groupPaths && groupPaths.split(',').length > 0) ? groupPaths.split(',') : []);

      if (committees) {
        this.$store.dispatch('setEuEpCommittees', committees.split(','));
      }

      if (this.$route.query.startDate && this.$route.query.endDate) {
        this.$store.dispatch('setDateRange', dateRangeISO);
      }

      if (query) {
        this.$store.dispatch('europarlGetAllTimelineItems');
      }
    },
  },

  watch: {
    trendAnalyticsExpanded(bool) {
      // Lets open the panels after the user altered the settings
      if (!bool) {
        this.panel = [0, 1, 2];
      } else {
        this.singlepanel = [0];
      }
    },
  },

  async mounted() {
    this.$gtag.pageview({
      page_path: '/eu/dashboard',
    });

    await this.$store.dispatch('europarlClearTimelineItems');
    await this.$store.dispatch('europarlSetCurrentSearch', {});

    await this.$store.dispatch('getFilterTypesForUser');
    await this.$store.dispatch('getEuroparlFilterTypesForUser').then(() => {
      this.europarlFiltersAreSet = true;
    });

    await this.$store.dispatch('clearMessages');
    await this.parseQueryParams(this.$route.query);

    await this.$store.dispatch('europarlGetAllSavedSearches');
    await this.$store.dispatch('getPreferences');
    this.$store.dispatch('setIsIE11');
    if (this.isIE11) {
      this.panel = [1, 2];
    }
  },
};
</script>

<style lang="scss">
  ul.plain-li {
    & {
      list-style: none;
    }
    & li::before {
      content: "\2022";
      color: teal;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
</style>
